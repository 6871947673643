<template>
    <div class="transparent">
        <v-expand-transition>
            <v-card flat v-show="show" class="transparent">
                <v-card-text
                    class="d-flex align-center justify-center"
                >
                {{$tr("Pull To Refresh")}}
                </v-card-text>
            </v-card>
        </v-expand-transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
        };
    },
    methods:{
        async view_banner(){
            await this.$sleep(700)
            this.show = true 
            await this.$sleep(2000)
            this.show = false 
        }
    }, 
    async mounted(){
        // await this.view_banner();
    }
};
</script>

<style>
</style>